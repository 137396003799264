import { NavLink } from 'react-router-dom';
import * as IoIcons from 'react-icons/io';
import React, { useState } from "react";

import "./Navigation.css";

export default function Navigation() {

    const [isMenuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!isMenuOpen);
    };

    return (
        <>
            <div className="mobile-nav">
                <div className="mobile-menu-icon" onClick={toggleMenu}>
                    {isMenuOpen ? (null) : (<IoIcons.IoIosMenu />)}
                </div>
                <nav className={`mobile-nav-menu ${isMenuOpen ? 'open' : ''}`}>
                    <ul className="mobile-ul-menu">
                        <li>
                            <div id="mobile-ul-close" onClick={toggleMenu}>
                                <IoIcons.IoIosClose />
                            </div>
                        </li>
                        <li>
                            <NavLink to="/about" className="mobile-nav-link">About <IoIcons.IoIosArrowDown /></NavLink>
                            <ul>
                                <li>
                                    <NavLink to="/about/consortium" className="mobile-nav-link">Consortium</NavLink>
                                </li>
                            </ul>
                        </li>
                        <div className="nav-breaker"></div>
                        <li>
                            <span className="mobile-nav-link">Open Calls <IoIcons.IoIosArrowDown /></span>
                            <ul>
                                <li>
                                    <NavLink to="/open-calls/open-calls" className="mobile-nav-link">Grants</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/open-calls/current-calls" className="mobile-nav-link">Current Call</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/open-calls/calls-evaluation" className="mobile-nav-link">Call for evaluators</NavLink>
                                </li>
                            </ul>
                        </li>
                        <div className="nav-breaker"></div>
                        <li>
                            <NavLink to="/orri-map" className="mobile-nav-link">Orri map</NavLink>
                        </li>
                        <div className="nav-breaker"></div>
                        <li>
                            <NavLink to="/news" className="mobile-nav-link">News</NavLink>
                        </li>
                        <div className="nav-breaker"></div>
                        <li>
                            <NavLink to="/project-resources" className="mobile-nav-link">Resources</NavLink>
                        </li>
                        <div className="nav-breaker"></div>
                        <li>
                            {/* <a href="mailto:info@reinforcing.eu" className="mobile-nav-link">Contact</a> */}
                            <NavLink to="/contact" className="mobile-nav-link">Contact</NavLink>
                        </li>
                    </ul>
                </nav>
            </div>
            <nav className="custom-nav">
                <ul>
                    <li>
                        <NavLink to="/about" className="nav-link">About <IoIcons.IoIosArrowDown /></NavLink>
                        <ul>
                            <li>
                                <NavLink to="/about/consortium" className="nav-link">Consortium</NavLink>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <span className="nav-link">Open Calls <IoIcons.IoIosArrowDown /></span>
                        <ul>
                            <li>
                                <NavLink to="/open-calls/open-calls" className="nav-link">Grants</NavLink>
                            </li>
                            <li>
                                <NavLink to="/open-calls/current-calls" className="nav-link">Current Call</NavLink>
                            </li>
                            <li>
                                <NavLink to="/open-calls/calls-evaluation" className="nav-link">Call for evaluators</NavLink>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <NavLink to="/orri-map" className="nav-link">Orri map</NavLink>
                    </li>
                    <li>
                        <NavLink to="/news" className="nav-link">News</NavLink>
                    </li>
                    <li>
                        <NavLink to="/project-resources" className="nav-link">Resources</NavLink>
                    </li>
                    <li>
                        {/* <a href="mailto:info@reinforcing.eu" className="nav-link-contact">Contact</a> */}
                        <NavLink to="/contact" className="nav-link-contact">Contact</NavLink>
                    </li>
                </ul>
            </nav>
        </>
    )
}
