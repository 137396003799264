import { Link } from "react-router-dom";
import React from "react";

import "./PostCard.css";

const formatDate = (date) => new Date(date).toLocaleDateString();

// export default function PostCard({ post }) {

//     const { title, date, slug, featuredImage } = post;

//     return (
//         <div className="post-card">
//             {featuredImage ? (
//                 <img
//                     id="post-card-img"
//                     src={featuredImage.node.sourceUrl}
//                     alt={featuredImage.node.altText}
//                 />
//             ) : null}
//             <h2 id="post-card-title">{title}</h2>
//             <div className="metadata">
//                 <p id="post-card-date">
//                     <span className="text-bold">Date:</span> {formatDate(date)}
//                 </p>
//             </div>
//             <Link to={`/news/${slug}`} id="post-card-link">
//                 <h4>View more →</h4>
//             </Link>
//         </div>
//     );
// }

// ------------------- TESTS ------------------- //

export default function PostCard({ post, isFirstRow }) {

    const { title, date, slug, featuredImage } = post;
  
    return (
        <div className={`post-card ${isFirstRow ? 'first-row-post' : ''}`}>
            {featuredImage ? (
                <img
                    id="post-card-img"
                    src={featuredImage.node.sourceUrl}
                    alt={featuredImage.node.altText}
                />
            ) : null}
            <h2 id="post-card-title">{title}</h2>
            <div className="metadata">
                <p id="post-card-date">
                    <span className="text-bold">Date:</span> {formatDate(date)}
                </p>
            </div>
            <Link to={`/news/${slug}`} id="post-card-link">
                <h4>View more →</h4>
            </Link>
        </div>
    );
    
}