import SocialAndNewsletter from "../../components/SocialAndNewsletter";
import Newsletter from "../../components/Newsletter";
import PostsList from "../../components/PostsList";
import ContactUs from "../../components/ContactUs";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import React, { useState } from "react";

import "./BlogPage.css";

export default function BlogPage() {

    const [selectedCategory, setSelectedCategory] = useState(0);

    const handleCategoryClick = (categoryNumber) => {
        setSelectedCategory(categoryNumber);
    };

    return (
        <div className="page-container">
            <Header className="header-news" />
            <div id="news-banner">
                <div className="content">
                    <h1 id="news-title">
                        News
                    </h1>
                </div>
            </div>
            <div id="categories-breadcrumb">
                <div className="content">
                    Home / News
                </div>
            </div>
            <div className="content">
                <div className="categories-banner">
                    <div className="categories-column-1">
                        <h3 id="follow-us-txt">
                            follow us on
                        </h3>
                        <a href="https://twitter.com/REINFORCING_eu" target="_blank" rel="noreferrer" className="categories-button-circular">
                            <span className="categories-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-twitter-x" viewBox="0 0 16 16">
                                    <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865l8.875 11.633Z" />
                                </svg>
                            </span>
                        </a>
                        <a href="https://www.linkedin.com/company/102311771/admin/feed/posts/?feedType=following" target="_blank" rel="noreferrer" className="categories-button-circular">
                            <span className="categories-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-linkedin" viewBox="0 0 30 30">
                                    <path d="M9,25H4V10h5V25z M6.501,8C5.118,8,4,6.879,4,5.499S5.12,3,6.501,3C7.879,3,9,4.121,9,5.499C9,6.879,7.879,8,6.501,8z M27,25h-4.807v-7.3c0-1.741-0.033-3.98-2.499-3.98c-2.503,0-2.888,1.896-2.888,3.854V25H12V9.989h4.614v2.051h0.065 c0.642-1.18,2.211-2.424,4.551-2.424c4.87,0,5.77,3.109,5.77,7.151C27,16.767,27,25,27,25z"></path>
                                </svg>
                            </span>
                        </a>
                        <a href="https://www.youtube.com/@REINFORCINGeu" target="_blank" rel="noreferrer" className="categories-button-circular">
                            <span className="categories-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-youtube" viewBox="0 0 16 16">
                                    <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.01 2.01 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.01 2.01 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31 31 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.01 2.01 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A100 100 0 0 1 7.858 2zM6.4 5.209v4.818l4.157-2.408z"/>
                                </svg>
                            </span>
                        </a>
                    </div>
                    <div className="categories-column-2">
                        <h4 id="categories-txt">
                            Categories:
                        </h4>
                        <div class="category-links">
                            <a class="category-link" onClick={ (event) => { event.preventDefault(); handleCategoryClick(0); }}>ALL</a>
                            <a class="category-link" onClick={ (event) => { event.preventDefault(); handleCategoryClick(1); }}>EVENTS</a>
                            <a class="category-link" onClick={ (event) => { event.preventDefault(); handleCategoryClick(2); }}>OPEN CALLS</a>
                            <a class="category-link" onClick={ (event) => { event.preventDefault(); handleCategoryClick(3); }}>EVALUATORS</a>
                            <a class="category-link" onClick={ (event) => { event.preventDefault(); handleCategoryClick(4); }}>ORRI</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="content">
                <div className="categories-braker"></div>
            </div>
            <div className="news-banner">
                <div className="content">
                    <PostsList categoryNumber={selectedCategory} />
                </div>
            </div>
            <div className="content">
                <Newsletter />
            </div>
            <ContactUs />
            <SocialAndNewsletter />
            <Footer />
        </div>
    );
}