import markerAmbassadorFacilitator from '../../images/marker_ambassador_facilitator.png';
import SocialAndNewsletter from "../../components/SocialAndNewsletter";
import markerFacilitator from '../../images/marker_facilitator.png';
import markerAmbassador from '../../images/marker_ambassador.png';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import { useForm, useController } from "react-hook-form";
import { Button, Form, Modal } from 'react-bootstrap';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState, useEffect } from "react";
import ContactUs from "../../components/ContactUs";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { countries } from 'countries-list';
import Select from 'react-select';
import * as yup from "yup";
import L from 'leaflet';

import 'bootstrap/dist/css/bootstrap.css';
import 'leaflet/dist/leaflet.css';
import './OrriMapMapPage.css';

export default function OrriMapPage() {
    const [ambassadors, setAmbassadors] = useState([]);
    const [expertiseAreas, setExpertiseAreas] = useState([]);
    const [roles, setRoles] = useState([]);
    const [id, setId] = useState("");
    const createAmbassadorSchema = yup.object({
        institution: yup.string().required(),
        email: yup.string().required().email(),
        city: yup.string().required(),
        country: yup.string().required(),
        public_profile: yup.string().url(),
        expertise_areas: yup.array().required(),
        role: yup.string().required()
    });

    const mapSchema = yup.object({
        finder_expertise_areas: yup.array(),
        finder_countries: yup.array()
    });

    const sendEmailschema = yup.object({
        name: yup.string().required(),
        email: yup.string().required().email(),
        subject: yup.string().required(),
        message: yup.string().required()
    });

    const { handleSubmit, register, reset, control, formState: { errors } } = useForm({
        resolver: yupResolver(createAmbassadorSchema)
    });

    const { handleSubmit: handleFilterAmbassadorsSubmit, reset: resetFilterAmbassadors, control: controlFilterAmbassadors } = useForm({
        resolver: yupResolver(mapSchema)
    });

    const { handleSubmit: handleSendEmailSubmit, register: registerSendEmail, reset: resetSendEmail, formState: { errors: errorsSendEmail } } = useForm({
        resolver: yupResolver(sendEmailschema)
    });

    const [showSendEmail, setShowSendEmail] = useState(false);

    const { field: { value: countryValue, onChange: countryOnChange, ...restCountryField } } = useController({ name: 'country', control });
    const { field: { value: areaValue, onChange: areaOnChange, ...restAreaField } } = useController({ name: 'expertise_areas', control });
    const { field: { value: roleValue, onChange: roleOnChange, ...restRoleField } } = useController({ name: 'role', control });
    const { field: { value: areaFinderValue, onChange: areaFinderOnChange, ...restFinderAreaField } } = useController({ name: 'finder_expertise_areas', control: controlFilterAmbassadors });
    const { field: { value: countryFinderValue, onChange: countryFinderOnChange, ...restFinderCountryField } } = useController({ name: 'finder_countries', control: controlFilterAmbassadors });
    const apiUrl = process.env.REACT_APP_API_URL;

    const returnCountries = [];
    const listCountries = Object.entries(countries);
    for (let i = 0; i < listCountries.length; i++) {
        if (listCountries[i][1].continent === "EU") {
            returnCountries.push({
                value: listCountries[i][0],
                label: listCountries[i][1].name
            });
        }
    }
    const iconAmbassador = new L.Icon({
        iconUrl: markerAmbassador,
        iconRetinaUrl: markerAmbassador,
        popupAnchor: [0, -0],
        iconSize: [40, 40],
        iconAnchor: [18, 40],
    });

    const iconAmbassadorFacilitator = new L.Icon({
        iconUrl: markerAmbassadorFacilitator,
        iconRetinaUrl: markerAmbassadorFacilitator,
        popupAnchor: [0, -0],
        iconSize: [40, 40],
        iconAnchor: [18, 40],
    });

    const iconFacilitator = new L.Icon({
        iconUrl: markerFacilitator,
        iconRetinaUrl: markerFacilitator,
        popupAnchor: [0, -0],
        iconSize: [40, 40],
        iconAnchor: [18, 40],
    });

    const handleCloseSendEmailModal = () => {
        setShowSendEmail(false);
        resetSendEmail({
            name: '',
            email: '',
            subject: '',
            message: ''
        });
    }

    const handleShowSendEmailModal = () => setShowSendEmail(true);

    const myOnSubmit = data => {
        const loginPayload = {
            id: id,
            name: data.name,
            email: data.email,
            subject: data.subject,
            message: data.message
        };
        fetch(`${apiUrl}/api/ambassadors/send-email`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(loginPayload)
        }).then(() => {
            setShowSendEmail(false);
            resetSendEmail({
                name: '',
                email: '',
                subject: '',
                message: ''
            });
        });
    }

    const onSubmit = async data => {
        const loginPayload = {
            institution: data.institution,
            city: data.city,
            country: data.country,
            email: data.email,
            publicProfile: data.public_profile,
            areas: data.expertise_areas.map(x => x.value),
            role: data.role
        };
        fetch(`${apiUrl}/api/ambassadors`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(loginPayload)
        }).then(() => {
            reset({
                institution: '',
                email: '',
                city: '',
                country: '',
                public_profile: '',
                expertise_areas: [],
                role: ''
            });
        });
    }

    const onFindSubmit = async data => {
        if ((data.finder_expertise_areas && data.finder_expertise_areas.length > 0) || (data.finder_countries && data.finder_countries.length > 0)) {
            let variables = "";
            if (data.finder_expertise_areas && data.finder_expertise_areas.length > 0 && data.finder_countries && data.finder_countries.length > 0) {
                variables = `expertiseAreas=${data.finder_expertise_areas.map(x => x.value).join(',')}&countries=${data.finder_countries.map(x => x.value).join(',')}`;
            } else {
                if (data.finder_expertise_areas && data.finder_expertise_areas.length > 0) {
                    variables = `expertiseAreas=${data.finder_expertise_areas.map(x => x.value).join(',')}`;
                } else {
                    variables = `countries=${data.finder_countries.map(x => x.value).join(',')}`;
                }
            }
            fetch(`${apiUrl}/api/ambassadors?${variables}`)
                .then((response) => response.json())
                .then((data) => {
                    setAmbassadors(data);
                });
        }
    }

    const resetFinder = () => {
        resetFilterAmbassadors({
            finder_expertise_areas: [],
            finder_countries: []
        });
        fetchAmbassadors();
    }

    const clickMarker = (id) => {
        fetch(`${apiUrl}/api/ambassadors/${id}`)
            .then((response) => response.json())
            .then((data) => {
                const ambassador = document.querySelector('.ambassadorInfo');
                const expertiseAreas = data.areas.map(x => `<p>${x}</p>`).join(' ');
                let color = "";
                if (data.role === "Ambassador") {
                    color = "#0d3c44";
                } else if (data.role === "Facilitator") {
                    color = "#0e757f";
                } else {
                    color = "#13b0bf";
                }
                ambassador.innerHTML = `<h5>Information</h5><div class="close">&times;</div><div class='info-content'><div class="role" style="color: ${color}">${data.role}</div><h6>Institution</h6><p>${data.institution}</p><h6>Areas of ORRI expertise</h6>${expertiseAreas}<button type="button" class="btn btn-primary send-mail">Send email</button></div>`;
                document.querySelector('.ambassadorInfo .close').addEventListener('click', () => {
                    ambassador.style.display = 'none';
                });
                document.querySelector('.ambassadorInfo .send-mail').addEventListener('click', () => {
                    setId(id);
                    handleShowSendEmailModal();
                });
                ambassador.style.display = 'block';
            });
    }

    const fetchAmbassadors = async () => {
        fetch(`${apiUrl}/api/ambassadors`)
            .then((response) => response.json())
            .then((data) => {
                setAmbassadors(data);
            });
    };

    const fetchExpertiseAreas = async () => {
        fetch(`${apiUrl}/api/expertise-areas`)
            .then((response) => response.json())
            .then((data) => {
                data = data.map(item => {
                    return {
                        value: item.id,
                        label: item.name
                    };
                });
                setExpertiseAreas(data);
            });
    };

    const fetchRoles = async () => {
        fetch(`${apiUrl}/api/ambassadors/roles`)
            .then((response) => response.json())
            .then((data) => {
                data = data.map(item => {
                    return {
                        value: item.id,
                        label: item.name
                    };
                });
                setRoles(data);
            });
    };

    useEffect(() => {
        fetchAmbassadors();
        fetchExpertiseAreas();
        fetchRoles();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const [checkboxChecked, setCheckboxChecked] = useState(false);

    const handleCheckboxChange = () => {
        setCheckboxChecked(!checkboxChecked);
    };

    return (
        <div className="page-container orrimap">
            <Modal show={showSendEmail} onHide={handleCloseSendEmailModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Send email</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate onSubmit={handleSendEmailSubmit(myOnSubmit)} id="sendEmailForm">
                        <Form.Group className="mb-4" controlId="sendEmail.name">
                            <Form.Label>Name</Form.Label>
                            <input
                                type="text"
                                className={`form-control ${errorsSendEmail.name ? 'is-invalid' : ''}`}
                                placeholder="name"
                                {...registerSendEmail("name", {
                                    required: "Field is required"
                                })}
                            />
                            {errorsSendEmail.name && <div className="d-grid gap-2 mt-2 error">{errorsSendEmail.name.message}</div>}
                        </Form.Group>
                        <Form.Group className="mb-4" controlId="sendEmail.email">
                            <Form.Label>Email address</Form.Label>
                            <input
                                type="email"
                                className={`form-control ${errorsSendEmail.email ? 'is-invalid' : ''}`}
                                placeholder="name@example.com"
                                {...registerSendEmail("email", {
                                    required: "Field is required",
                                    pattern: {
                                        value: /\S+@\S+\.\S+/,
                                        message: "Entered value does not match email format",
                                    }
                                })}
                            />
                            {errorsSendEmail.email && <div className="d-grid gap-2 mt-2 error">{errorsSendEmail.email.message}</div>}
                        </Form.Group>
                        <Form.Group className="mb-4" controlId="sendEmail.subject">
                            <Form.Label>Subject</Form.Label>
                            <input
                                type="text"
                                className={`form-control ${errorsSendEmail.subject ? 'is-invalid' : ''}`}
                                placeholder="subject"
                                {...registerSendEmail("subject", {
                                    required: "Field is required"
                                })}
                            />
                            {errorsSendEmail.subject && <div className="d-grid gap-2 mt-2 error">{errorsSendEmail.subject.message}</div>}
                        </Form.Group>
                        <Form.Group className="mb-4" controlId="sendEmail.message">
                            <Form.Label>Message</Form.Label>
                            <textarea rows={8}
                                className={`form-control ${errorsSendEmail.message ? 'is-invalid' : ''}`}
                                {...registerSendEmail("message", {
                                    required: "Field is required"
                                })}
                            ></textarea>
                            {errorsSendEmail.message && <div className="d-grid gap-2 mt-3 error">{errorsSendEmail.message.message}</div>}
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseSendEmailModal}>
                        Close
                    </Button>
                    <Button type="submit" variant="primary" form="sendEmailForm">
                        Send
                    </Button>
                </Modal.Footer>
            </Modal>
            <Header className="header-orrimap" />
            <div id="title" className="orri-title">
                <div className="content">
                    <div className="title-subtitle">ORRI Map</div>
                    <div className="title-title">Orri ambassadors and facilitators map</div>
                </div>
            </div>
            <div id="breadcrumb">
                <div className="content">
                    Home / ORRI Map
                </div>
            </div>
            <div id="content">
                <div className="content">
                    <h2>1. Our current ORRI ambassadors and facilitators</h2>
                    <div className="left mb-5">
                        <MapContainer center={[51.505, 0]} zoom={4} scrollWheelZoom={false}>
                            <TileLayer
                                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            {ambassadors.map((amb) => (() => {
                                if (amb.role === "Ambassador") {
                                    return (
                                        <Marker id={amb.id} key={`marker-${amb.id}`} position={[amb.latitude, amb.longitude]} icon={iconAmbassador} eventHandlers={{
                                            click: (e) => {
                                                clickMarker(e.target.options.id);
                                            },
                                        }}></Marker>
                                    )
                                } else if (amb.role === "Facilitator") {
                                    return (
                                        <Marker id={amb.id} key={`marker-${amb.id}`} position={[amb.latitude, amb.longitude]} icon={iconFacilitator} eventHandlers={{
                                            click: (e) => {
                                                clickMarker(e.target.options.id);
                                            },
                                        }}></Marker>
                                    )
                                } else {
                                    return (
                                        <Marker id={amb.id} key={`marker-${amb.id}`} position={[amb.latitude, amb.longitude]} icon={iconAmbassadorFacilitator} eventHandlers={{
                                            click: (e) => {
                                                clickMarker(e.target.options.id);
                                            },
                                        }}></Marker>
                                    )
                                }
                            })())}
                        </MapContainer>
                    </div>
                    <div className="right mb-5">
                        <h4>Filter by</h4>
                        <div className="form-group mt-3">
                            <div className="ambassadorInfo"></div>
                            <Form noValidate className="mb-5" onSubmit={handleFilterAmbassadorsSubmit(onFindSubmit)} id="findForm">
                                <label htmlFor="finder_expertise_areas">Areas of ORRI expertise</label>
                                <Select
                                    className={`select-input mt-1`}
                                    placeholder="Select"
                                    isClearable
                                    isSearchable
                                    options={expertiseAreas}
                                    value={areaFinderValue}
                                    onChange={(option) => areaFinderOnChange(option || [])}
                                    isMulti
                                    {...restFinderAreaField}
                                />
                                <label className="mt-4" htmlFor="finder_countries">Country</label>
                                <Select
                                    className={`select-input mt-1`}
                                    placeholder="Select"
                                    isClearable
                                    isSearchable
                                    options={returnCountries}
                                    value={countryFinderValue}
                                    onChange={(option) => countryFinderOnChange(option || [])}
                                    isMulti
                                    {...restFinderCountryField}
                                />
                                <Button type="submit" variant="primary" className="btn mt-3 find" form="findForm">
                                    Find
                                </Button>
                                <Button type="button" variant="secondary" onClick={resetFinder} className="btn mt-3 reset" form="findForm">
                                    Reset
                                </Button>
                            </Form>
                        </div>
                    </div>
                    <h2>2. What is an ORRI Facilitator and an ORRI Ambassador?</h2>
                    <p className="mt-4">An ORRI facilitator is an entity with strong experience in supporting and facilitating ORRI institutional changes.<br />
                        An ORRI ambassador is an entity which have hands-on experiences of ORRI implementation in their own institution and territory.</p>
                    <h2>3. Join the ORRI Facilitators and Ambassadors Map!</h2>
                    <h4>Why joining?</h4>
                    <ul className="mt-4">
                        <li>Showcase your ORRI experience in the REINFORCING map</li>
                        <li>Possibility of being contacted by actors interested in ORRI</li>
                        <li>More visibility for becoming a potential partner in projects funded by REINFORCING</li>
                    </ul>
                    <h2>4. Want to be part of the REINFORCING ORRI Community?</h2>
                    <p>If you are motivated to be listed in the ORRI Map, please register your entity through this form:</p>
                    <Form noValidate className="mb-5" onSubmit={handleSubmit(onSubmit)} id="createForm">
                        <div className="form-group mt-3">
                            <label htmlFor="institution">Institution</label>
                            <input type="text" {...register("institution")} className={`form-control mt-1 ${errors.institution ? 'is-invalid' : ''}`} id="institution" />
                        </div>
                        <div className="form-group mt-3">
                            <label htmlFor="city">City</label>
                            <input type="text" {...register("city", {
                                required: "Required"
                            })} className={`form-control mt-1 ${errors.city ? 'is-invalid' : ''}`} id="city" />
                        </div>
                        <div className="form-group mt-3">
                            <label htmlFor="country">Country</label>
                            <Select
                                defaultValue={restCountryField.defaultValue || 'Select'}
                                className={`select-input mt-1 ${errors.country ? 'is-invalid' : ''}`}
                                placeholder="Select"
                                isClearable
                                isSearchable
                                options={returnCountries}
                                value={countryValue ? returnCountries.find(x => x.value === countryValue) : countryValue}
                                onChange={option => countryOnChange(option ? option.value : option)}
                                {...restCountryField}
                            />
                        </div>
                        <div className="form-group mt-3">
                            <label htmlFor="email">Email</label>
                            <input type="email" {...register("email")} className={`form-control mt-1 ${errors.email ? 'is-invalid' : ''}`} id="email" />
                        </div>
                        <div className="form-group mt-3">
                            <label htmlFor="public_profile">Public profile URL</label>
                            <input type="text" {...register("public_profile")} className={`form-control mt-1 ${errors.public_profile ? 'is-invalid' : ''}`} id="public_profile" />
                        </div>
                        <div className="form-group mt-3">
                            <label htmlFor="expertise_areas">Areas of expertise</label>
                            <Select
                                className={`select-input mt-1 ${errors.expertise_areas ? 'is-invalid' : ''}`}
                                placeholder="Select"
                                isClearable
                                isSearchable
                                options={expertiseAreas}
                                value={areaValue}
                                onChange={(option) => areaOnChange(option || [])}
                                isMulti
                                isOptionDisabled={() => areaValue && areaValue.length >= 5}
                                {...restAreaField}
                            />
                        </div>
                        <div className="form-group mt-3">
                            <label htmlFor="role">Role </label>
                            <Select
                                defaultValue={restRoleField.defaultValue || 'Select'}
                                className={`select-input mt-1 ${errors.role ? 'is-invalid' : ''}`}
                                placeholder="Select"
                                isClearable
                                isSearchable
                                options={roles}
                                value={roleValue ? roles.find(x => x.value === countryValue) : roleValue}
                                onChange={option => roleOnChange(option ? option.value : option)}
                                {...restCountryField}
                            />
                        </div>
                        <div className="privacy-policy-checkbox">
                            <input type="checkbox" id="checkbox" checked={checkboxChecked} onChange={handleCheckboxChange}/> I have read and agree to the <a href="/Privacy policy.pdf" download className="footer-link">privacy policy</a>.
                        </div>
                        <Button type="submit" variant="primary" className="btn btn-primary mt-5 register" form="createForm" id="register-btn" disabled={!checkboxChecked}>
                            Register
                        </Button>
                    </Form>
                </div>
            </div>
            <ContactUs />
            <SocialAndNewsletter />
            <Footer />
        </div>
    );
}