import { gql, useQuery } from "@apollo/client";
import PostCard from "../components/PostCard";
import React from "react";

import "./PostsList.css";

const GET_ALL_POSTS = gql`
  query MyQuery {
    posts {
      nodes {
        databaseId
        title
        date
        slug
        featuredImage {
          node {
            altText
            sourceUrl
          }
        }
      }
    }
  }
`;

export default function PostsList({ categoryNumber }) {

  // ------------------- REAL DATA ------------------- //

    const showAll = categoryNumber === 0;
  
    const { loading, error, data } = useQuery(GET_ALL_POSTS);

    if (loading) return <p className="mt-4">Loading posts...</p>;
    if (error) return <p className="mt-4">Error :&#40;</p>;

    const postsFound = Boolean(data?.posts.nodes.length);
    if (!postsFound) {
      return <p className="mt-4">No matching posts found.</p>;
    }

    const postsRows = [];
    for (let i = 0; i < data.posts.nodes.length; i += 3) {
      const row = data.posts.nodes.slice(i, i + 3);
      postsRows.push(row);
    }

    if (showAll) {
      return (
        <div className="posts-list">
          {postsRows.map((row, rowIndex) => (
            <div className={rowIndex === postsRows.length - 1 && row.length < 3 ? "posts-last-row" : "posts-row"} key={rowIndex}>
              {row.map((post, index) => (
                <PostCard key={index} post={post} />
              ))}
            </div>
          ))}
        </div>
      );
    } else {
      if (categoryNumber === 1) {         // Events
        for (const i of data.posts.nodes) { console.log(i); }
        return (
          <div className="posts-list">
            {postsRows.map((row, rowIndex) => (
              <div className={rowIndex === postsRows.length - 1 && row.length < 3 ? "posts-last-row" : "posts-row"} key={rowIndex}>
                {row.map((post, index) => (
                  <PostCard key={index} post={post} />
                ))}
              </div>
            ))}
          </div>
        );
      } else if (categoryNumber === 2) {  // Open Calls
        return (
          <div className="posts-list">
            {postsRows.map((row, rowIndex) => (
              <div className={rowIndex === postsRows.length - 1 && row.length < 3 ? "posts-last-row" : "posts-row"} key={rowIndex}>
                {row.map((post, index) => (
                  <PostCard key={index} post={post} />
                ))}
              </div>
            ))}
          </div>
        );
      } else if (categoryNumber === 3) {  // Evaluators
        return (
          <div className="posts-list">
            {postsRows.map((row, rowIndex) => (
              <div className={rowIndex === postsRows.length - 1 && row.length < 3 ? "posts-last-row" : "posts-row"} key={rowIndex}>
                {row.map((post, index) => (
                  <PostCard key={index} post={post} />
                ))}
              </div>
            ))}
          </div>
        );
      } else if (categoryNumber === 4) {  // ORRI
        return (
          <div className="posts-list">
            {postsRows.map((row, rowIndex) => (
              <div className={rowIndex === postsRows.length - 1 && row.length < 3 ? "posts-last-row" : "posts-row"} key={rowIndex}>
                {row.map((post, index) => (
                  <PostCard key={index} post={post} />
                ))}
              </div>
            ))}
          </div>
        );
      } else if (categoryNumber === 5) { //
        return (
          <div className="posts-list-home-mobile">
            <div className="first-column">
              {data.posts.nodes.slice(0, 1).map((post) => (
                <PostCard key={post.databaseId} post={post} />
              ))}
              {data.posts.nodes.slice(1, 3).map((post, index, array) => (
                <React.Fragment key={post.databaseId}>
                  <PostCard post={post} />
                  {index < array.length - 1 && <hr className="white-hr" />}
                </React.Fragment>
              ))}
            </div>
          </div>
        );
      } else {
        return (
          <div className="posts-list-home">
            <div className="first-column">
              {data.posts.nodes.slice(0, 1).map((post) => (
                <PostCard key={post.databaseId} post={post} />
              ))}
            </div>
            <div className="second-column">
              {data.posts.nodes.slice(1, 3).map((post, index, array) => (
                <React.Fragment key={post.databaseId}>
                  <PostCard post={post} />
                  {index < array.length - 1 && <hr className="white-hr" />}
                </React.Fragment>
              ))}
            </div>
          </div>
        );
      }
    }

  // ------------------- FAKE DATA ------------------- //

    // const fakePostsData = {
    //   posts: {
    //     nodes: [
    //       {
    //         databaseId: 1,
    //         title: "Fake Post 1",
    //         date: "2024-02-15",
    //         slug: "fake-post-1",
    //         featuredImage: {
    //           node: {
    //             altText: "Fake Image 1",
    //             sourceUrl: "https://fakeimageurl.com/image1.jpg",
    //           },
    //         },
    //       },
    //       {
    //         databaseId: 2,
    //         title: "Fake Post 2 | Fake Post 2 | Fake Post 2 | Fake Post 2 | Fake Post 2 | Fake Post 2 | Fake Post 2",
    //         date: "2024-02-16",
    //         slug: "fake-post-2",
    //         featuredImage: {
    //           node: {
    //             altText: "Fake Image 2",
    //             sourceUrl: "https://fakeimageurl.com/image2.jpg",
    //           },
    //         },
    //       },
    //       {
    //         databaseId: 3,
    //         title: "Fake Post 3",
    //         date: "2024-02-16",
    //         slug: "fake-post-3",
    //         featuredImage: {
    //           node: {
    //             altText: "Fake Image 3",
    //             sourceUrl: "https://fakeimageurl.com/image3.jpg",
    //           },
    //         },
    //       },
    //       {
    //         databaseId: 4,
    //         title: "Fake Post 4",
    //         date: "2024-02-16",
    //         slug: "fake-post-4",
    //         featuredImage: {
    //           node: {
    //             altText: "Fake Image 4",
    //             sourceUrl: "https://fakeimageurl.com/image4.jpg",
    //           },
    //         },
    //       },
    //       {
    //         databaseId: 5,
    //         title: "Fake Post 5",
    //         date: "2024-02-16",
    //         slug: "fake-post-5",
    //         featuredImage: {
    //           node: {
    //             altText: "Fake Image 5",
    //             sourceUrl: "https://fakeimageurl.com/image5.jpg",
    //           },
    //         },
    //       },
    //       {
    //         databaseId: 6,
    //         title: "Fake Post 6",
    //         date: "2024-02-16",
    //         slug: "fake-post-6",
    //         featuredImage: {
    //           node: {
    //             altText: "Fake Image 6",
    //             sourceUrl: "https://fakeimageurl.com/image6.jpg",
    //           },
    //         },
    //       },
    //       {
    //         databaseId: 7,
    //         title: "Fake Post 7",
    //         date: "2024-02-16",
    //         slug: "fake-post-7",
    //         featuredImage: {
    //           node: {
    //             altText: "Fake Image 7",
    //             sourceUrl: "https://fakeimageurl.com/image7.jpg",
    //           },
    //         },
    //       },
    //       {
    //         databaseId: 8,
    //         title: "Fake Post 8",
    //         date: "2024-02-16",
    //         slug: "fake-post-8",
    //         featuredImage: {
    //           node: {
    //             altText: "Fake Image 8",
    //             sourceUrl: "https://fakeimageurl.com/image8.jpg",
    //           },
    //         },
    //       },
    //       {
    //         databaseId: 9,
    //         title: "Fake Post 9",
    //         date: "2024-02-16",
    //         slug: "fake-post-9",
    //         featuredImage: {
    //           node: {
    //             altText: "Fake Image 9",
    //             sourceUrl: "https://fakeimageurl.com/image9.jpg",
    //           },
    //         },
    //       },
    //     ],
    //   },
    // };

    // const showAll = categoryNumber === 0;

    // const postsFound = Boolean(fakePostsData?.posts.nodes.length);
    // if (!postsFound) {
    //   return <p className="mt-4">No matching posts found.</p>;
    // }

    // const postsRows = [];
    // for (let i = 0; i < fakePostsData.posts.nodes.length; i += 3) {
    //   const row = fakePostsData.posts.nodes.slice(i, i + 3);
    //   postsRows.push(row);
    // }

    // if (showAll) {
    //   return (
    //     <div className="posts-list">
    //       {postsRows.map((row, rowIndex) => (
    //         <div className={rowIndex === postsRows.length - 1 && row.length < 3 ? "posts-last-row" : "posts-row"} key={rowIndex}>
    //           {row.map((post, index) => (
    //             <PostCard key={index} post={post} />
    //           ))}
    //         </div>
    //       ))}
    //     </div>
    //   );
    // } else {
    //   if (categoryNumber === 5) {
    //     return (
    //       <div className="posts-list-home-mobile">
    //         <div className="first-column">
    //           {fakePostsData.posts.nodes.slice(0, 1).map((post) => (
    //             <PostCard key={post.databaseId} post={post} />
    //           ))}
    //           {fakePostsData.posts.nodes.slice(1, 3).map((post, index, array) => (
    //             <React.Fragment key={post.databaseId}>
    //               <PostCard post={post} />
    //               {index < array.length - 1 && <hr className="white-hr" />}
    //             </React.Fragment>
    //           ))}
    //         </div>
    //       </div>
    //     );
    //   } else {
    //     return (
    //       <div className="posts-list-home">
    //         <div className="first-column">
    //           {fakePostsData.posts.nodes.slice(0, 1).map((post) => (
    //             <PostCard key={post.databaseId} post={post} />
    //           ))}
    //         </div>
    //         <div className="second-column">
    //           {fakePostsData.posts.nodes.slice(1, 3).map((post, index, array) => (
    //             <React.Fragment key={post.databaseId}>
    //               <PostCard post={post} />
    //               {index < array.length - 1 && <hr className="white-hr" />}
    //             </React.Fragment>
    //           ))}
    //         </div>
    //       </div>
    //     );
    //   }
    // }
    
}
