import logoHeader from "../../images/current-calls/current_calls_bg_3.svg";
import ContactUsCurrentCalls from "../../components/ContactUsCurrentCalls";
import SocialAndNewsletter from "../../components/SocialAndNewsletter";
import submission from "../../images/grants/submission.svg";
import Newsletter from "../../components/Newsletter";
import scope from "../../images/grants/scope.svg";
import forms from "../../images/grants/forms.svg";
import faqs from "../../images/grants/faqs.svg";
import Footer from "../../components/Footer";
import Header from "../../components/Header";

import "./Nov2023CallPage.css";

export default function Nov2023CallPage() {
    return (
        <div className="page-container grants">
            <Header className="header-grants-oc" />
            <div id="title">
                <div className="content">
                    <div className="title-subtitle">Closed Call</div>
                    <div className="title-title">November 2023 Call</div>
                </div>
            </div>
            <div id="breadcrumb">
                <div className="content">
                    Home / Open Calls / Closed Call / November 2023 Call
                </div>
            </div>
            <div id="content">
                <div id="header">
                    <div className="content">
                        <div className="schema">
                            <div className="body">
                                <div className="title">
                                    1st REINFORCING Open Call
                                </div>
                                <div className="subtitle">
                                    Boosters call
                                </div>
                                <div className="text">
                                    Applicants who already have experience with ORRI approaches and actions could
                                    submit their proposals until <b>30th of January 2024</b> at <b>17:00</b> (Brussels time).<br></br>
                                </div>
                                <div className="text">
                                    On January 17th REINFORCING hosted a webinar in which we disclosed the insights,
                                    and guided participants through the application process of our first Booster Open Call.
                                    You can find the <b>webinar recording</b> <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=9HrOhwxBfF0">here</a>.
                                </div>
                            </div>
                            <div className="logo">
                                <div className="background">
                                    <img src={logoHeader} alt="reinforcing" />
                                </div>
                                <div className="logo-title">
                                    The 1st REINFORCING Open Call (Boosters Call) <br /> <span className="highlighted">is now closed!</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="application-separator">
                    <div className="content">
                        <div className="breaker"></div>
                    </div>
                </div>
                <div className="application-banner">
                    <div className="content">
                        <div className="open-calls-content">
                            <div className="open-calls-1">
                                <div id="application-1">
                                    <h5 id="application-section-8">
                                        Applicants for Boosters Call were required to <b>have a track record
                                        in ORRI approaches and to describe their experience</b> in the field.
                                    </h5>
                                    <h1 id="application-section-2">
                                        Application process
                                    </h1>
                                    <h5 id="application-section-3">
                                        In order to apply, applicants were required to complete a template and
                                        upload it in the online form. No further sections beyond those requested
                                        were considered in the application. Proposals should not have been longer
                                        than 5 pages, and applications exceeding the limits were not considered.
                                    </h5>
                                </div>
                            </div>
                            <div className="open-calls-2">
                                <div id="application-2">
                                    <h1 id="application-section-4">
                                        Who could apply
                                    </h1>
                                    <h5 id="application-section-5">
                                        <ul>
                                            <li>
                                                Applicants <b>established in an EU country (Member State
                                                or associated country)</b>, including their outermost
                                                regions or associated countrieseligible to receive HEU
                                                grants, provided that the applicants were not covered by
                                                the Council sanctions in force.
                                            </li>
                                            <li>
                                                <b>Applicants must be legal entities</b> such as, but not
                                                limited to, Civil Society Organizations (CSO), Non-Governmental
                                                Organizations (NGO), Higher Education Institutions, Research
                                                Centres and Small and Medium Enterprises (SME).
                                            </li>
                                            <li>
                                                Applicants must <b>declare their financial stability</b>.
                                            </li>
                                            <li>
                                                Applicants <b>shall not have any potential conflicts of
                                                interest with the REINFORCING consortium or any of its
                                                partners</b> (notably, they should not have a legal
                                                relationship with any organization member of the
                                                REINFORCING consortium, including affiliated entities).
                                            </li>
                                        </ul>
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="current-calls-objectives-2">
                    <div className="content">
                        <div className="title">
                            Objectives and topic of the call
                        </div>
                        <div className="text">
                            This funding call is aimed at supporting applicants with proven experience in Open and
                            Responsible Research and Innovation practices who would like to strengthen and institutionalize
                            their ORRI approach. The call emphasizes challenges such as multi-stakeholder and citizen
                            engagement, intellectual property (IP) rights mechanisms, and impact assessment of Responsability.
                        </div>
                        <div className="objective-button">
                            <a target="_blank" href="/Guideline for applicants OLD.pdf" id="guide-link">
                                Guide for applicants →
                            </a>
                        </div>
                    </div>
                </div>
                <div id="call-calendar">
                    <div className="content">
                        <div className="title">
                            call calendar
                        </div>
                        <div className="dates">
                            <div className="title">
                                Important dates for the application process are described in the following table:
                            </div>
                            <ul id="dates-table">
                                <li>
                                    <div className="date">
                                        30th November 2023
                                    </div>
                                    <div className="text">
                                        Official launch of the call
                                    </div>
                                </li>
                                <li>
                                    <div className="date">
                                        30th January 2024
                                    </div>
                                    <div className="text">
                                        Deadline for proposal submission - 17:00 (Brussels time)
                                    </div>
                                </li>
                                <li>
                                    <div className="date">
                                        February 2024
                                    </div>
                                    <div className="text">
                                        Evaluation process
                                    </div>
                                </li>
                                <li>
                                    <div className="date">
                                        March 2024
                                    </div>
                                    <div className="text">
                                        Communication to applicants selected for funding
                                    </div>
                                </li>
                                <li>
                                    <div className="date">
                                        April 2024
                                    </div>
                                    <div className="text">
                                        Start of the funded projects
                                    </div>
                                </li>
                                <li>
                                    <div className="date">
                                        30th November 2024
                                    </div>
                                    <div className="text">
                                        End of the funded projects
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div id="more-info">
                    <div className="title">
                        More information
                    </div>
                    <div className="elements-mobile">
                        <div className="content">
                            <div className="element">
                                <img src={scope} alt="Scope of the call" />
                                <div className="title">
                                    Scope of the call
                                </div>
                                <div className="text">
                                    Find the call text
                                </div>
                                <a href="/2nd REINFORCING Open Call.pdf" download>
                                    <span id="information-buttons">
                                        More info
                                    </span>
                                </a>
                            </div>
                            <div className="element">
                                <img src={submission} alt="Submission Link" />
                                <div className="title" id="submission-title">
                                    Submission page
                                </div>
                                <div className="text">
                                    Access to the submission platform to apply
                                </div>
                                <a href="/open-calls/open-calls/incubators " class="disabled">
                                    <span id="information-buttons">
                                        Apply
                                    </span>
                                </a>
                            </div>
                            <div className="element">
                                <img src={forms} alt="Applicants Forms" />
                                <div className="title">
                                    Guidelines for Applicants
                                </div>
                                <div className="text">
                                    Download the Guide for Applicants
                                </div>
                                <a href="/Guidelines for applicants OLD.pdf" download>
                                    <span id="information-buttons">
                                        Download
                                    </span>
                                </a>
                            </div>
                            <div className="element">
                                <img src={faqs} alt="FAQs" />
                                <div className="title">
                                    FAQs
                                </div>
                                <div className="text">
                                    Find the answers to frequently asked questions
                                </div>
                                <a href="/FAQs.pdf" download>
                                    <span id="information-buttons">
                                        View faqs
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ContactUsCurrentCalls />
            <Newsletter />
            <SocialAndNewsletter />
            <Footer />
        </div>
    )
}