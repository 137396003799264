import logoTitle from "../../images/oss-platform/services_title_logo.svg";
import SocialAndNewsletter from "../../components/SocialAndNewsletter";
import InfoIcon from "../../images/oss-platform/info_icon.svg";
import Newsletter from "../../components/Newsletter";
import ContactUs from "../../components/ContactUs";
import React, { useEffect, useState } from 'react';
import Header from "../../components/Header";
import Footer from "../../components/Footer";

import Icon1 from "../../images/oss-platform/tab_icon_1.svg";
import Icon2 from "../../images/oss-platform/tab_icon_2.svg";
import Icon3 from "../../images/oss-platform/tab_icon_3.svg";
import Icon4 from "../../images/oss-platform/tab_icon_4.svg";
import Icon5 from "../../images/oss-platform/tab_icon_5.svg";

import TabCardIcon1 from "../../images/oss-platform/tab_card_icon_1.svg";
import TabCardIcon2 from "../../images/oss-platform/tab_card_icon_2.svg";
import TabCardIcon3 from "../../images/oss-platform/tab_card_icon_3.svg";
import TabCardIcon4 from "../../images/oss-platform/tab_card_icon_4.svg";
import TabCardIcon5 from "../../images/oss-platform/tab_card_icon_5.svg";
import TabCardIcon6 from "../../images/oss-platform/tab_card_icon_6.svg";

import "./OSSPlatformServicesPage.css";

import { NotificationContainer, NotificationManager } from 'react-notifications';
import "react-notifications/lib/notifications.css";

export default function OSSPlatformServicesPage() {

    const [service, setService] = useState(0);
    const [selectedItems, setSelectedItems] = useState([]);

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const tab = queryParams.get('tab');
        if (tab) { setService(parseInt(tab, 10)); }
    }, []);

    const handleTabChange = (number) => { setService(number); }

    const selectItem = (item) => {
        setSelectedItems((prevSelectedItems) => {
            if (prevSelectedItems.includes(item)) {
                return prevSelectedItems.filter(selectedItem => selectedItem !== item);
            } else {
                return [...prevSelectedItems, item];
            }
        });
    }

    const handleSubmit = () => {
        NotificationManager.error('Search is not available yet', 'Error!');
    }

    return (
        <div className="page-container oss-platform-services">
            <NotificationContainer />
            <Header className="header-oss-platform-services" />
            <div id="title">
                <div className="content">
                    <div className="banner">
                        <div className="banner-text">
                            <div className="banner-title">
                                <h1>orri one stop source</h1>
                            </div>
                            <div className="banner-subtitle">
                                {service === 0 && (
                                    <h3>grants</h3>
                                )}
                                {service === 1 && (
                                    <h3>orri community</h3>
                                )}  
                                {service === 2 && (
                                    <h3>tools</h3>
                                )}  
                                {service === 3 && (
                                    <h3>training</h3>
                                )}  
                                {service === 4 && (
                                    <h3>pathways</h3>
                                )}  
                            </div>
                        </div>
                        <div className="banner-logo">
                            <img src={logoTitle} alt="reinforcing" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="introduction">
                <div className="content">
                    <div className="introduction-body">
                        {service === 0 && (
                            <h5>
                                REINFORCING provides financial support to European
                                organizations in the form of grants to enable and
                                support their journey towards open and responsible
                                research and innovation.
                            </h5>
                        )}
                        {service === 1 && (
                            <h5>
                                From experienced organizations to facilitators,
                                this is where you can find peers who, just like you,
                                have had their own ORRI journeys and can share more
                                about their experiences.
                            </h5>
                        )}
                        {service === 2 && (
                            <h5>
                                This is where you find a wealth of useful resources
                                to support you in designing your ORRI project with
                                concrete tools and best practices for implementing ORRI.
                            </h5>
                        )}
                        {service === 3 && (
                            <h5>
                                Training is essential to acquire the right skillset
                                you need to implement ORRI projects. Here you can find
                                training modules on general and specific topics related
                                to ORRI.
                            </h5>
                        )}
                        {service === 4 && (
                            <h5>
                                 
                            </h5>
                        )}
                    </div>
                </div>
            </div>
            <div className="services">
                <div className="searcher">
                    <div className="search-group">
                        <div className="input-wrapper">
                            <input type="search" id="search-input" class="form-control" placeholder="Search..." aria-label="Search" aria-describedby="search-addon" />
                            <span className="search-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
                                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/>
                                </svg>
                            </span>
                        </div>
                    </div>
                </div>
                <div className="services-tabs">
                    <ul class="nav nav-tabs justify-content-center">
                        <li className="nav-item">
                            <a 
                                className={`nav-link ${service === 0 ? 'active' : ''}`} 
                                aria-current="page" 
                                href="#"
                                onClick={(event) => { event.preventDefault(); handleTabChange(0); }}
                            >
                                <div className="tab_title">
                                    <img src={Icon1} alt="icon_grants" />
                                    <h1>Grants</h1>
                                </div>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a 
                                className={`nav-link ${service === 1 ? 'active' : ''}`} 
                                href="#"
                                onClick={(event) => { event.preventDefault(); handleTabChange(1); }}
                            >
                                <div className="tab_title">
                                    <img src={Icon2} alt="icon_orri_community" />
                                    <h1>ORRI Community</h1>
                                </div>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a 
                                className={`nav-link ${service === 2 ? 'active' : ''}`} 
                                href="#" 
                                onClick={(event) => { event.preventDefault(); handleTabChange(2); }}
                            >
                                <div className="tab_title">
                                    <img src={Icon3} alt="icon_tools" />
                                    <h1>Tools</h1>
                                </div>
                            </a>
                        </li>
                        <li className="nav-item disabled">
                            <a 
                                className={`nav-link ${service === 3 ? 'active' : ''}`} 
                                href="#" 
                                onClick={(event) => { event.preventDefault(); handleTabChange(3); }}
                            >
                                <div className="tab_title">
                                    <img src={Icon4} alt="icon_training" />
                                    <h1>Training</h1>
                                </div>
                            </a>
                        </li>
                        <li className="nav-item disabled">
                            <a 
                                className={`nav-link ${service === 4 ? 'active' : ''}`} 
                                href="#" 
                                onClick={(event) => { event.preventDefault(); handleTabChange(4); }}
                            >
                                <div className="tab_title">
                                    <img src={Icon5} alt="icon_pathways" />
                                    <h1>Pathways</h1>
                                </div>
                            </a>
                        </li>
                    </ul>
                    <div className="content-2">
                        <div className="services-tabs-body">
                            {service === 0 && (
                                <div className="services-tabs-body-data">
                                    <div className="services-tabs-body-data-title">
                                        <h1>Information</h1>
                                    </div>
                                    <div className="services-tabs-body-data-cards">
                                        <div className="services-tabs-cards-info">
                                            <h3>Find general information about funding</h3>
                                            <div className="services-tabs-card">
                                                <a href="/open-calls/open-calls" id="open-call-link">General Information</a>
                                                <img id="card_icon_1" src={TabCardIcon1} alt="card_icon" />
                                            </div>
                                        </div>
                                        <div className="services-tabs-cards-info">
                                            <h3>Find funding for your ORRI project</h3>
                                            <div className="services-tabs-card">
                                                <a href="/open-calls/current-calls" id="current-call-link">Current Call</a>
                                                <img id="card_icon_2" src={TabCardIcon2} alt="card_icon" />
                                            </div>
                                        </div>
                                        <div className="services-tabs-cards-info disabled">
                                            <h3>Info on ORRI Booster and Incubator projects</h3>
                                            <div className="services-tabs-card">
                                                <a href="#" id="previous-call-link">Previous Call</a>
                                                <img id="card_icon_4" src={TabCardIcon4} alt="card_icon" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {service === 1 && (
                                <div className="services-tabs-body-data">
                                    <div className="services-tabs-body-data">
                                        <div className="services-tabs-body-data-cards" id="services-tabs-body-data-cards-2">
                                            <div className="services-tabs-cards-info">
                                                <div className="services-tabs-card">
                                                    <a href="/orri-map" id="open-call-link">Orri Map</a>
                                                    <img id="card_icon_3" src={TabCardIcon3} alt="card_icon" />
                                                </div>
                                            </div>
                                            <div className="services-tabs-cards-info disabled">
                                                <div className="services-tabs-card">
                                                    <a href="/open-calls/current-calls" id="current-call-link">REINFORCING Grantees</a>
                                                    <img id="card_icon_5" src={TabCardIcon5} alt="card_icon" />
                                                </div>
                                            </div>
                                            <div className="services-tabs-cards-info disabled">
                                                <div className="services-tabs-card">
                                                    <a href="#" id="previous-call-link">Sibling Projects & Platforms</a>
                                                    <img id="card_icon_6" src={TabCardIcon6} alt="card_icon" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {service === 2 && (
                               <div className="services-tabs-body-data">
                                    <div className="services-tabs-tools-introduction">
                                        <h2>You can select more than one option:</h2>
                                        <button 
                                            type="submit"
                                            id="results-btn"
                                            onClick={handleSubmit}
                                            disabled={selectedItems.length === 0}
                                        >
                                            GO TO RESULTS PAGE →
                                        </button>
                                    </div>
                                    <div className="services-tabs-columns-container">
                                        <div className="services-tabs-column">
                                            <h3>I work in</h3>
                                            <div className="services-tabs-column-works">
                                                <div className={`services-tabs-tools-card-1 ${selectedItems.includes("Research") ? 'selected' : ''}`} onClick={(event) => { event.preventDefault(); selectItem("Research"); }}>
                                                    <h4>Research</h4>
                                                    <button type="button" class="btn btn-secondary" data-toggle="tooltip" data-placement="top" title="Higher education institutions/universities, research organizations">
                                                        <img src={InfoIcon} alt="info_icon" />
                                                    </button>
                                                </div>
                                                <div className={`services-tabs-tools-card-1 ${selectedItems.includes("Government") ? 'selected' : ''}`} onClick={(event) => { event.preventDefault(); selectItem("Government"); }}>
                                                    <h4>Government, Public Sector</h4>
                                                    <button type="button" class="btn btn-secondary" data-toggle="tooltip" data-placement="top" title="Government at any level/ministries, agencies, public bodies">
                                                        <img src={InfoIcon} alt="info_icon" />
                                                    </button>
                                                </div>
                                                <div className={`services-tabs-tools-card-1 ${selectedItems.includes("NGO") ? 'selected' : ''}`} onClick={(event) => { event.preventDefault(); selectItem("NGO"); }}>
                                                    <h4>NGO, Civil Society</h4>
                                                    <button type="button" class="btn btn-secondary" data-toggle="tooltip" data-placement="top" title="Non-government organizations, not-for-profits, civil society organizations">
                                                        <img src={InfoIcon} alt="info_icon" />
                                                    </button>
                                                </div>
                                                <div className={`services-tabs-tools-card-1 ${selectedItems.includes("Business") ? 'selected' : ''}`} onClick={(event) => { event.preventDefault(); selectItem("Business"); }}>
                                                    <h4>Business</h4>
                                                    <button type="button" class="btn btn-secondary" data-toggle="tooltip" data-placement="top" title="Private sector organizations, industry, entrepreneurs">
                                                        <img src={InfoIcon} alt="info_icon" />
                                                    </button>
                                                </div>
                                                <div className={`services-tabs-tools-card-1 ${selectedItems.includes("Other") ? 'selected' : ''}`} onClick={(event) => { event.preventDefault(); selectItem("Other"); }}>
                                                    <h4>Other</h4>
                                                    <button type="button" class="btn btn-secondary" data-toggle="tooltip" data-placement="top" title="Can’t find yourself in any category? Click here">
                                                        <img src={InfoIcon} alt="info_icon" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="services-tabs-column">
                                            <h3>Areas I am interested in</h3>
                                            <div className="services-tabs-column-interests">
                                                <div className="services-tabs-column-interests-1">
                                                    <div className={`services-tabs-tools-card-2 ${selectedItems.includes("AI") ? 'selected' : ''}`} onClick={(event) => { event.preventDefault(); selectItem("AI"); }}>
                                                        <h4>AI Ethics</h4>
                                                    </div>
                                                    <div className={`services-tabs-tools-card-2 ${selectedItems.includes("Corporal") ? 'selected' : ''}`} onClick={(event) => { event.preventDefault(); selectItem("Corporal"); }}>
                                                        <h4>Corporal Social Responsability</h4>
                                                    </div>
                                                    <div className={`services-tabs-tools-card-2 ${selectedItems.includes("Innovation") ? 'selected' : ''}`} onClick={(event) => { event.preventDefault(); selectItem("Innovation"); }}>
                                                        <h4>Ethics in Innovation</h4>
                                                    </div>
                                                    <div className={`services-tabs-tools-card-2 ${selectedItems.includes("Environmental") ? 'selected' : ''}`} onClick={(event) => { event.preventDefault(); selectItem("Environmental"); }}>
                                                        <h4>Environmental Sustainability</h4>
                                                    </div>
                                                    <div className={`services-tabs-tools-card-2 ${selectedItems.includes("Economic") ? 'selected' : ''}`} onClick={(event) => { event.preventDefault(); selectItem("Economic"); }}>
                                                        <h4>Economic Sustainability</h4>
                                                    </div>
                                                    <div className={`services-tabs-tools-card-2 ${selectedItems.includes("Governance") ? 'selected' : ''}`} onClick={(event) => { event.preventDefault(); selectItem("Governance"); }}>
                                                        <h4>Governance</h4>
                                                    </div>
                                                </div>
                                                <div className="services-tabs-column-interests-2">
                                                    <div className={`services-tabs-tools-card-2 ${selectedItems.includes("Open") ? 'selected' : ''}`} onClick={(event) => { event.preventDefault(); selectItem("Open"); }}>
                                                        <h4>Open Access & Open Science</h4>
                                                    </div>
                                                    <div className={`services-tabs-tools-card-2 ${selectedItems.includes("Ethics") ? 'selected' : ''}`} onClick={(event) => { event.preventDefault(); selectItem("Ethics"); }}>
                                                        <h4>Ethics in Research & Innovation</h4>
                                                    </div>
                                                    <div className={`services-tabs-tools-card-2 ${selectedItems.includes("Responsible") ? 'selected' : ''}`} onClick={(event) => { event.preventDefault(); selectItem("Responsible"); }}>
                                                        <h4>Open & Responsible Innovation</h4>
                                                    </div>
                                                    <div className={`services-tabs-tools-card-2 ${selectedItems.includes("Diversity") ? 'selected' : ''}`} onClick={(event) => { event.preventDefault(); selectItem("Diversity"); }}>
                                                        <h4>Diversity, Equality, Inclusivity</h4>
                                                    </div>
                                                    <div className={`services-tabs-tools-card-2 ${selectedItems.includes("Public") ? 'selected' : ''}`} onClick={(event) => { event.preventDefault(); selectItem("Public"); }}>
                                                        <h4>Public Engagement</h4>
                                                    </div>
                                                    <div className={`services-tabs-tools-card-2 ${selectedItems.includes("Social") ? 'selected' : ''}`} onClick={(event) => { event.preventDefault(); selectItem("Social"); }}>
                                                        <h4>Social Sustainability</h4>
                                                    </div>
                                                </div>
                                            </div>  
                                        </div>
                                        <div className="services-tabs-column">
                                            <h3>I search for</h3>
                                            <div className="services-tabs-column-works">
                                                <div className={`services-tabs-tools-card-1 ${selectedItems.includes("Tools") ? 'selected' : ''}`} onClick={(event) => { event.preventDefault(); selectItem("Tools"); }}>
                                                    <h4>Tools</h4>
                                                    <button type="button" class="btn btn-secondary" data-toggle="tooltip" data-placement="top" title="Find tools for the implementation of your ORRI project">
                                                        <img src={InfoIcon} alt="info_icon" />
                                                    </button>
                                                </div>
                                                <div className={`services-tabs-tools-card-1 ${selectedItems.includes("Success") ? 'selected' : ''}`} onClick={(event) => { event.preventDefault(); selectItem("Success"); }}>
                                                    <h4>Success Stories /Experiences</h4>
                                                    <button type="button" class="btn btn-secondary" data-toggle="tooltip" data-placement="top" title="Be inspired by ORRI projects and their outcomes">
                                                        <img src={InfoIcon} alt="info_icon" />
                                                    </button>
                                                </div>
                                                <div className={`services-tabs-tools-card-1 ${selectedItems.includes("Publications") ? 'selected' : ''}`} onClick={(event) => { event.preventDefault(); selectItem("Publications"); }}>
                                                    <h4>Publications</h4>
                                                    <button type="button" class="btn btn-secondary" data-toggle="tooltip" data-placement="top" title="Access important publications in the ORRI domain">
                                                        <img src={InfoIcon} alt="info_icon" />
                                                    </button>
                                                </div>
                                                <div className={`services-tabs-tools-card-1 ${selectedItems.includes("Recommendations") ? 'selected' : ''}`} onClick={(event) => { event.preventDefault(); selectItem("Recommendations"); }}>
                                                    <h4>Recommendations</h4>
                                                    <button type="button" class="btn btn-secondary" data-toggle="tooltip" data-placement="top" title="Find policy recommendations">
                                                        <img src={InfoIcon} alt="info_icon" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>                                  
                               </div>
                            )}
                            {service === 3 && (
                               <div className="services-tabs-body-data">
                                    <div className="services-tabs-body-data">
                                        <div className="services-tabs-body-data-cards" id="services-tabs-body-data-cards-3">
                                            <div className="services-tabs-cards-info">
                                                <div className="services-tabs-card disabled">
                                                    <a href="/orri-map" id="open-call-link">Crash Courses</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                               </div>
                            )}
                            {service === 4 && (
                                <div className="services-tabs-body-data">
                                    
                                </div>
                            )}
                        </div>
                        {service === 0 && (
                            <div className="services-tabs-body-links-container">
                                <div className="services-tabs-body-links">
                                    <div className="services-tabs-link-card">
                                        <h1>Interested in match-making?</h1>
                                        <a href="/orri-map" id="orri-map-link">Find ORRI practitioners across Europe →</a>
                                    </div>
                                    <div className="services-tabs-link-card">
                                        <h1>Interested in building capacity?</h1>
                                        <a href="#" id="building-link">Find ORRI training resources →</a>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <Newsletter />
            <ContactUs />
            <SocialAndNewsletter />
            <Footer />
        </div>
    );
}